<template>
  <div class="chat-footer">
    <div class="chat-footer__emoji-wrap" v-if="isEmojiOpened">
      <div class="emoji-content">
        <div
            class="emoji-item"
            v-for="emoji in emojiArray"
            :key="emoji.description"
            @click="addEmojiToText"
        >
          {{ emoji.emoji }}
        </div>
      </div>
    </div>
    <div class="chat-footer__file-input">
      <input type="file" id="chat-file" ref="chat-file" multiple @change="saveFile">
      <label for="chat-file">
        <svg-icon size="size20x20" name="add-chat-file" />
      </label>
    </div>
    <textarea
        rows="1"
        ref="message-input"
        :key="textFieldRerenderCount"
        class="chat-footer__message-input adaptive-textarea"
        v-model="form.message"
        placeholder="Type here..."
        :class="!form.message && 'chat-footer__message-input_empty'"
    />
<!-- Old ones -->
<!--    <span-->
<!--        class="chat-footer__message-input"-->
<!--        :class="!form.message && 'chat-footer__message-input_with-placeholder'"-->
<!--        role="textbox"-->
<!--        contenteditable-->
<!--        :key="textFieldRerenderCount"-->
<!--        ref="message-input"-->
<!--        @input="saveMessage"-->
<!--    >-->
<!--      {{ form.message }}-->
<!--    </span>-->
    <button class="chat-footer__emoji-button" @click="toggleEmojiWrap">
      <svg-icon size="size20x20" name="add-chat-emoji" />
    </button>
    <button v-if="form.message || form.files.length" @click="sendMessage">
      <svg-icon size="size20x20" name="chat-send-icon" />
    </button>
  </div>
</template>

<script>
import emoji from '@/helpers/emoji.json'
import createAutoResizeTextarea from '@/helpers/adaptiveTextarea.js'

export default {
  name: "ChatFooter",
  data() {
    return {
      form: {
        message: null,
        files: []
      },
      isEmojiOpened: false,
      textFieldRerenderCount: 0
    }
  },
  computed: {
    emojiArray() {
      return [...emoji]
    }
  },
  methods: {
    // saveMessage(e) {
    //   this.form.message = e.target.innerText.trim()
    // },
    toggleEmojiWrap() {
      this.isEmojiOpened = !this.isEmojiOpened
    },
    addEmojiToText(e) {
      const emoji = e.target.innerText
      const input = this.$refs['message-input']

      const startPosition = input.selectionStart,
          endPosition = input.selectionEnd,
          message = this.form.message
      let cursorPosition = startPosition

      // add emoji in message:
      if (message) {
        // add in current cursor position
        this.form.message = message.substring(0, startPosition) + emoji + message.substring(endPosition, message.length)
      } else this.form.message = emoji

      // move cursor after emoji:
      setTimeout(() => {
        cursorPosition += emoji.length
        input.selectionStart = input.selectionEnd = cursorPosition
      }, 10)
    },
    saveFile() {
      const filesFromInput = this.$refs['chat-file'].files
      if (filesFromInput.length > 1) {
        filesFromInput.forEach(file => this.form.files.push(file))
      } else {
        this.form.files.push(filesFromInput[0])
      }
    },
    sendMessage() {
      let formData = new FormData()
      const keys = Object.keys(this.form)

      keys.forEach(key => {
        // if we have value
        if (this.form[key]) {
          if (key === 'files') {
            for(let i = 0; i < this.form.files.length; i++){
              let file = this.form.files[i];
              formData.append('files[' + i + ']', file);
            }
          } else {
            formData.append(key, this.form[key])
          }
        }
      })

      this.$emit('sendMessage', formData)
    },
    clearData() {
      this.form = { message: null, files: [] }
      this.textFieldRerenderCount++
    }
  },
  mounted() {
    createAutoResizeTextarea()
  }
}
</script>

<style lang="stylus">
.chat-footer
  background #1D2130
  box-shadow 0 0 12px rgba(25, 26, 30, 0.5)
  border-radius 16px 16px 0 0
  position relative
  //bottom 0
  padding 20px
  width 100%
  display flex
  align-items flex-end
  gap 19px

  & svg
    fill #7B8B97
  &__file-input
    & input
      display none
    & label
      display block
  &__message-input
    flex-grow 1
    color #D8D8D8
    background none
    overflow-y hidden
    min-height auto
    border-radius 0
    padding 0
    //width auto
    //overflow-y auto
    //&_with-placeholder::before
    //  content 'Type here...'
    //  color #7B8B97
  &__emoji-wrap
    position absolute
    width 100%
    height 200px
    top -210px
    left 0
    background #1D2130
    box-shadow 0 0 12px rgba(25, 26, 30, 0.5)
    padding 20px 0 20px 20px
    border-radius 16px
    z-index 10
.emoji-content
  display flex
  flex-wrap wrap
  gap 1rem
  overflow-y auto
  width 100%
  height 100%
  padding-right 20px
.emoji-item
  font-size 1.5rem
  line-height 1.5rem
  flex-grow 1
  display flex
</style>
