<template>
  <view-container name="personal-chat" class="personal-chat">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>
          </div>
        </template>
        <template v-slot:chat>
          <div class="user-info-block" @click="openProfile">
            <div class="user-info-block__avatar">
<!--              <span class="online-status"/>-->
<!--              <img :src="require('@/assets/images/member1.png')" alt="Darlene Robertson">-->
              <a-avatar :src="userForChatAvatar">
                <a-icon slot="icon" type="user"/>
              </a-avatar>
            </div>
            <span class="user-info-block__name">{{ userForChatName }}</span>
          </div>
        </template>
        <template v-slot:last>
          <button class="personal-chat__more-button" @click="isMenuOpen = true">
            <svg-icon size="size20x20" name="icoMoreFill" />
          </button>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div ref="dialog-content" class="dialog-content">
        <MessageComponent
            v-for="message in messages"
            :key="message.id"
            :message="message"
            :isOurUserMessage="ourUser.id === message.from_user_id"
            :userForChat="userForChat"
            :ourUser="ourUser"
        />
      </div>
    </view-content>
    <Popup :open="isMenuOpen" @close="isMenuOpen = false">
      <p v-if='copiedUrl'>The link has been copied</p>
      <LinkWithIcon @click="copyLink">
        <template v-slot:icon>
          <svg-icon size="size24x24" class="" name="icoShare" />
        </template>
        Share this Profile
      </LinkWithIcon>
      <LinkWithIcon @click="deleteChat">
        <template v-slot:icon>
          <svg-icon size="size24x24" class="" name="delete-icon" />
        </template>
        Delete this Chat
      </LinkWithIcon>
<!--      <LinkWithIcon>-->
<!--        <template v-slot:icon>-->
<!--          <svg-icon size="size24x24" class="" name="report-icon" />-->
<!--        </template>-->
<!--        &lt;!&ndash;TODO: Add user name&ndash;&gt;-->
<!--        Report  Darlene Robertson-->
<!--      </LinkWithIcon>-->
    </Popup>
    <view-footer>
      <ChatFooter @sendMessage="sendMessage" ref="chat-footer" />
    </view-footer>
  </view-container>
</template>

<script>
import Icon from '@/components/Icon'
import MessageComponent from '@/components/MessageComponent'
import Popup from '@/components/Popup'
import LinkWithIcon from '@/components/LinkWithIcon/LinkWithIcon'
import ChatFooter from '@/components/ChatFooter'
import { copyTextToClipboard } from '@/helpers'
import {deleteDialog, getMessages, sendMessages} from '@/api/chat'
import moment from 'moment-timezone'

export default {
  name: 'PersonalChat',
  props: ['userID'],
  components: {
    Icon,
    MessageComponent,
    Popup,
    LinkWithIcon,
    ChatFooter
  },
  data() {
    return {
      isMenuOpen: false,
      copiedUrl: false,
      messageUpdater: null,
      messages: [],
      userForChat: null
    }
  },
  computed: {
    userForChatAvatar() {
      return this.userForChat?.photo
    },
    userForChatName() {
      return this.userForChat?.name
    },
    ourUser() {
      return this.$store.state.auth.user
    }
  },
  methods: {
    copyLink() {
      copyTextToClipboard(`${window.location.origin}/account/${this.userID}`)
      this.copiedUrl = true
      setTimeout(() => {
        this.isMenuOpen = false
        this.copiedUrl = false
      }, 2500)
    },
    openProfile() {
      this.$router.push({ name: 'account', params: { userID: this.userID } })
    },
    async getMessages() {
      try {
        const request = await getMessages(this.userID)
        const messages = [...request?.data]
        messages.forEach(message => {
          message.time = moment(message.created_at).format('H:mm')
        })

        if (messages.length !== this.messages.length) {
          this.messages = messages
        }
      } catch (e) {
        console.log(e)
      }
    },
    async sendMessage(formData) {
      try {
        await sendMessages(formData, this.userID)
        this.$refs["chat-footer"].clearData()
        await this.getMessages()
      } catch (e) {
        console.log(e)
      }
    },
    updateDialogPosition() {
      const dialog = this.$refs['dialog-content']
      dialog.scrollTop = dialog.scrollHeight
    },
    async deleteChat() {
      try {
        this.$router.push({name: 'chat'})
        await deleteDialog(this.userID)
        this.$store.dispatch('loadDialogs')
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.$store.dispatch('getUserByID', this.userID).then((res) => {
      this.userForChat = res
    })
    this.getMessages()
    this.messageUpdater = setInterval(() => {
      this.getMessages()
    }, 30000)
    // this.updateDialogPosition()
  },
  updated() {
    this.updateDialogPosition()
  },
  beforeDestroy() {
    clearInterval(this.messageUpdater)
  }
}
</script>

<style lang="stylus">
.online-status
  width 10px
  height 10px
  background #0CEB15
  border-radius 50%
  position absolute
  top 0
  right 0
  z-index 10
.personal-chat
  &.view
    & .view-content
      padding-right 0
  & .user-info-block
    display flex
    align-items center
    gap 0.5rem
    margin-right auto

    &__name
      font-weight bold
      font-size 16px
      line-height 24px
    &__avatar
      width 32px
      height 32px
      position relative
      & img
        border-radius 50%
        width 100%
        height 100%
        object-fit cover
        object-position top
      & .ant-avatar
        width 100%
        height 100%
        border-radius 50%
  &__more-button
    & svg
      margin-right 0 !important
.popup
  & .link-with-icon__icon
    & svg
      fill #7B8B97
.dialog-content
  overflow-y auto
  overflow-x visible
  height 100%
  padding-right 16px
  //position relative
  //&::-webkit-scrollbar
  //  position absolute
  //  right 0
</style>
