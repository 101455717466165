<template>
  <div class="message-wrap" :class="isOurUserMessage && 'message-wrap_user'">
    <div class="message-wrap__avatar">
<!--      <span class="online-status" v-if="!isOurUserMessage"/>-->
<!--      <img :src="image" alt="Darlene Robertson">-->
      <a-avatar :src="userAvatar">
        <a-icon slot="icon" type="user"/>
      </a-avatar>
    </div>
    <div class="message-wrap__content-inner">
      <div class="message-wrap__message">
        <div class="message-wrap__message-images" v-if="files">
          <img :src="img" alt="" v-for="img in files" :key="img">
        </div>
        <div class="message-wrap__message-text" v-if="text">{{ text }}</div>
      </div>
      <div
        class="message-wrap__status-block"
        :class="{
          'message-wrap__status-block--user': isOurUserMessage,
          'message-wrap__status-block--read': message.is_read,
          }"
        >
        <span class="message-wrap__time-slug">{{ time }}</span>
        <svg-icon :name="'chat-double-status'" :className="'message-wrap__read-message'"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageComponent",
  props: {
    message: {
      required: true,
    },
    image: {
      type: String,
      required: false,
    },
    isOurUserMessage: {
      type: Boolean
    },
    userForChat: {
      required: false
    },
    ourUser: {
      required: false
    }
  },
  computed: {
    userAvatar() {
      return this.isOurUserMessage ? this.ourUser?.photo : this.userForChat?.photo
    },
    text() {
      return this.message.message
    },
    time() {
      return this.message.time
    },
    files() {
      return this.message.files
    }
  }
}
</script>

<style lang="stylus" scoped>
.message-wrap
  display flex
  align-items flex-start
  gap 0.5rem
  margin-bottom 12px
  &:last-child
    margin-bottom 0
  &__avatar
    width 24px
    height 24px
    position relative
    & img
      border-radius 50%
      width 100%
      height 100%
      object-fit cover
      object-position top
    & .ant-avatar
      width 100%
      height 100%
      border-radius 50%
    & .online-status
      width 8px
      height 8px
  &__content-inner
    max-width 60%
  &__message
    background-color rgba(86, 204, 242, 0.2)
    border-radius 0 12px 12px 12px
    margin-bottom 0.5rem
    overflow hidden
  &__message-text
    padding 0.5rem
    white-space pre-wrap
  &__message-images
    display flex
    flex-wrap wrap
    gap 3px
    & img
      max-width 100%
  &__status-block
    padding 0 0.5rem
    &--user .message-wrap__read-message
      order -1
    &--read .message-wrap__read-message
      fill #D21341
  &__time-slug
    font-weight normal
    font-size 10px
    color #7B8B97
    margin-right 8px
  &_user
    justify-content flex-end
    & .message-wrap__avatar
      order 1
    & .message-wrap__message
      background rgba(210, 19, 65, 0.4)
      border-radius 12px 0 12px 12px
    & .message-wrap__status-block
      display flex
      justify-content flex-end
      align-items center
  &__read-message
    width 14px
    height 8px
    fill #7B8B97
</style>
