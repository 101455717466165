<template>
  <button class='link-with-icon' @click='click'>
    <span v-if='$slots.icon' class='link-with-icon__icon'>
      <slot name='icon'/>
    </span>
    <span class='link-with-icon__text'>
      <slot/>
    </span>
    <span v-if='$slots.right' class='link-with-icon__right'>
      <slot name='right'/>
    </span>
  </button>
</template>

<script>
export default {
  name: 'LinkWithIcon',
  props: {
    to: {
      type: [String, Object],
    }
  },
  methods: {
    click() {
      if (this.to) {
        this.$router.push(this.to)
      }
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.link-with-icon {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 12px 0;
  width: 100%;
}
.link-with-icon:last-of-type {
  border-bottom: 0;
}
.link-with-icon__icon {
  margin-right: 15px;
}
/*.link-with-icon__icon > svg {*/
/*  fill: #fff;*/
/*}*/
.link-with-icon__text {
  font-size: 16px;
  color: #D8D8D8;
}
.link-with-icon__right {
  margin-left: auto;
}
</style>
